import React, { useState, useEffect } from "react";
import Input from "./input";
import Password from "./password";
import { useNavigate } from "react-router-dom";
import Enter from "./enter";

const SignupForm = () => {
    const navigate = useNavigate()
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [text, setText] = useState("Sign Up For GoValuate")
    const [form, setForm] = useState({
        primaryEmail: "",
        name: "",
        password: "",
        confirmPassword: "",
        studentId: "",
        role: "student",
    });

    useEffect(() => {
        setText("Sign Up For GoValuate")
    }, [form])

    function updateName(name) {
        setForm(prevFrom => ({
            ...prevFrom,
            name: name
        }))
    }  
    
    function updatePrimaryEmail(primaryEmail) {
        setForm(prevFrom => ({
            ...prevFrom,
            primaryEmail: primaryEmail,
        }))
    } 
    
    function updatePassword(password) {
        setForm(prevFrom => ({
            ...prevFrom,
            password: password
        }))
    } 

    function updateConfirmPassword(confirmPassword) {
        setForm(prevFrom => ({
            ...prevFrom,
            confirmPassword: confirmPassword
        }))
    } 
    
    function updateStudentId(studentId) {
        setForm(prevFrom => ({
            ...prevFrom,
            studentId: studentId
        }))
    } 

    function updateRole(role) {
        if (role === "professor") {
            updateStudentId("NA");
        } else {
            updateStudentId("");
        }
        updateFormRole(role);

    }

    function updateFormRole(role) {
        setForm(prevFrom => ({
            ...prevFrom,
            role: role
        }))
    }

    function submitForm(e) {
        e.preventDefault();

        for (const value in form) {
            if (form[value] === "") {
                setText("Please fill out all form fields")
                return;
            }
        }

        if (form.name.length > 100) {
            setText("Name must have 99 or fewer characters");
            return;
        }

        if (form.password.length > 64) {
            setText("Password must have 63 or fewer characters");
            return;
        }

        if (form.studentId.length > 25) {
            setText("Student id must have 24 or fewer characters");
            return;
        }

        if (form.primaryEmail.length > 255) {
            setText("Email must have 254 or fewer characters")
            return;
        }

        if (form.primaryEmail.includes(" ")) {
            setText("Remove whitespace from email");
            return;
        }
        
        // Additional email validation checks
        if (!form.primaryEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setText("Invalid email format");
            return;
        }

        if (form.name.trim() === "") {
            setText("Remove whitespace from name")
            return;
        }

        if (((form.password !== form.confirmPassword) || form.password.length < 12) && form.password !== "") {
            if (form.password.length < 12) {
                setText("Password is not at least 12 characters")
            } else {
                setText("Passwords don't match")
            }
            
            return;
        }

        const url = process.env.REACT_APP_USER_API_URL + "/sign-up"

        const urlOptions = {    
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form)
        }

        makeRequest(url, urlOptions);
        
    }

    const makeRequest = async (url, urlOptions) => {
        if (requestLifeCycle) {
            return;
        }
        
        try {
            setRequestLifeCycle(true);
            const res = await fetch(url, urlOptions);            
            if (res.status === 200) {
                setText("Success, please wait for a verification email");
                navigate(`/confirm-email/${form.primaryEmail}/`);
            } else if (res.status === 410) {
                setText("Please try again")
            } else if (res.status === 400) {
                setText("Invalid form field");
            } else if (res.status === 405) {
                setText("Coming soon!")
            } else if (res.status === 409) {
                setText("Email already taken");
            } else {
                setText("An error occurred")
            }

        } catch (error) {
            setText("An error occurred");
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }

    }

    return (
        <>
            <div className="signup-container">
                <div className="add-a-course-form-container">
                    <div className="add-a-course-form-content-container">
                        <form className="form-width">
                            <div className="add-course-title">
                                GoValuate Sign Up
                            </div>
                            <div className="account-settings-content">
                                <div className="required-container">
                                    All Fields Required
                                </div>   
                                <div className="banner top">
                                    Enter your account information below
                                </div>
                                <div className="role">
                                    <div className={`role-box ${form.role === "professor" ? "active" : "" }`} onClick={() => {updateRole("professor")}}>
                                        Professor
                                    </div>
                                    <div className={`role-box ${form.role === "student" ? "active" : "" }`} onClick={() => {updateRole("student")}}>
                                        Student
                                    </div>
                                </div>
                                <Input top={"Name"} require={true} value={form.name} updateValue={updateName} placeholder={"John Doe"} autoComplete={"name"} type={"text"}/>
                                <Input top={"Email Address"} require={true} value={form.primaryEmail} updateValue={updatePrimaryEmail} placeholder={"johndoe@university.edu"} autoComplete={"email"} type={"text"}/>
                                <Password top={"Password (12+ Characters)"} require={true} password={true} value={form.password} updateValue={updatePassword} placeholder={""} autoComplete={"new-password"} type={"text"}/>
                                <Password top={"Confirm Password"} require={true} password={true} value={form.confirmPassword} updateValue={updateConfirmPassword} placeholder={""} autoComplete={"new-password"} type={"text"}/>
                                {
                                    form.role === "student" 

                                    ?

                                        <Input top={"Student ID"} require={true} value={form.studentId} updateValue={updateStudentId} placeholder={""} autoComplete={"new-password"} type={"text"}/>

                                    :

                                    <>
                                    </>
                                        
                                }
                                <div className="forgot-verify" style={{ justifyContent: "flex-end" }}>
                                    <span className="actions" onClick={() => {
                                        navigate("/login")
                                    }}> Log in? </span>
                                </div>
                                <div className="priv-term"> By signing up, you agree to our <span className="term-priv-span" onClick={() => {
                                    navigate("/privacy-policy");
                                }}>Privacy Policy</span> and <span className="term-priv-span" onClick={() => {
                                    navigate("/terms-and-conditions")
                                }}>Terms and Conditions</span>.</div>
                                <Enter requestLifeCycle={requestLifeCycle} submitForm={submitForm} text={text}/>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignupForm;