import React, { useState } from "react";
import Card from "./card";
import Paper from "./paper";
import Tablet from "./tablet";
import Pencil from "./pencil";
import Stylus from "./stylus";
import Workspace from "./workspace";
import Stack from "./stack";
import FeatureRow from "./featureRow";
import SmallCard from "./smallCard";
import Checkmark from "./checkmark";
import Grade from "./grade";
import Institution from "./institution";
import HowWorksRow from "./howWorksRow";
import Hyrbid from "./hybrid";
import Globe from "./globe";
import useWindowWidth from "./useWindowWidth";
import OnlyLogo from "./onlyLogo";
import BottomHeader from "./bottomHeader";
import Pdf from "./pdf";
import Papers from "./papers";
import Stopwatch from "./stopwatch";
import Speed from "./speed";
import { useNavigate } from "react-router-dom";

const Body = (props) => {
    const navigate = useNavigate();
    const width = useWindowWidth(1250);
    const widthTwo = useWindowWidth(920);
    const widthThree = useWindowWidth(500);
    const widthSix = useWindowWidth(700);
    const widthSeven = useWindowWidth(615);
    const widthEight = useWindowWidth(380);
    const widthNine = useWindowWidth(340);
    const featureSet = [
        {
            feature: "Web Platform",
            free: true,
            soon: false,
        },        
        {
            feature: "Configurable Code Editor",
            free: true,
            soon: false,
        },
        {
            feature: "Unit Test Autograding",
            free: true,
            soon: false,
        },
        {
            feature: "Traditional Autograding",
            free: true,
            soon: false,
        },
        {
            feature: "Entry Codes",
            free: true,
            soon: false,
        },
        {
            feature: "Feedback Comments",
            free: true,
            soon: false,
        },
        {
            feature: "Evaluation Statistics",
            free: true,
            soon: false,
        },
        {
            feature: "Submissions Export",
            free: true,
            soon: false,
        },        
        {
            feature: "Data Export",
            free: true,
            soon: false,
        },
        {
            feature: "Multiple Forms",
            free: true,
            soon: false,
        },
        {
            feature: "Multiple Instructors",
            free: true,
            soon: false,
        },
        {
            feature: "Dynamic Grade Publishing",
            free: true,
            soon: false,
        },
        {
            feature: "Real-Time Grading",
            free: true,
            soon: false,
        },
        {
            feature: "Student Desktop App",
            free: false,
            soon: true,
        },
        {
            feature: "Lockdown Environment",
            free: false,
            soon: true,
        },
        {
            feature: "LMS Integration",
            free: false,
            soon: true,
        },
        {
            feature: "Single Sign On (SSO)",
            free: false,
            soon: true,
        },
        {
            feature: "Group Evaluations",
            free: false,
            soon: true,
        },
        {
            feature: "AI Grading",
            free: false,
            soon: true,
        }
    ]

    return (
        <>
            <div className="body-content">
                <div className="blue-back">
                    <div className="center-text" style={{ rowGap: widthSeven ? "14px" : "" }}>
                        <div className="title" style={{ fontSize: widthEight ? "20px" : "" }}>
                            Effortless, { widthSix ? "" : "Hybrid," } Secure Evaluation
                            {
                                widthSix 

                                ?

                                    <>
                                    </>

                                :

                                    <>
                                        ... with <span className="govaluate" onClick={() => {
                                                        navigate("/sign-up")
                                                    }}> GoValuate </span>
                                    </>
                            }
                        </div>
                        <div className="subtitle" style={{ fontSize: widthNine ? "14px" : "" }}>
                            { widthSix ? "B" : "Simply b" }uild your evaluation, and we take care of the rest
                        </div>
                        <div className="try-it" style={{ marginTop: widthSeven ? "14px" : "" }} onClick={() => {
                            navigate("/sign-up")
                        }}>
                            Try GoValuate for Free
                        </div>
                    </div>
                    <div className="center-for-cards">
                        <div className="card-container-absolute">                            
                            <Card left={<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                        </svg>} 
                                    right={<Stylus />} button={"Instant Autograding"} title={"Unit Test Autograding with File-To-Question Mapping"}/>
                            <Card left={<Pencil />} right={<Pdf />} button={"Configurable Editor"} title={"Configurable Code Editor Question Type (70+ Languages & Plaintext)"}/>
                            <Card left={<Paper />} right={<Tablet />} button={"Unit Test GUI"} title={"Unit-Test-Creation Graphical User Interface"}/>
                            <Card left={<Stack />} right={<Workspace />} button={"Build Evaluations"} title={"Render Markdown & LaTeX in Custom Evaluation Format"}/>
                            <Card left={<Stopwatch />} right={<Speed />} button={"Real-time Grading"} title={"View Multi-Type Autograded Answers Instantly Upon Start"}/>
                        </div>
                    </div>
                    <div className="chart-works" style={{ marginTop: "300px" }} ref={props.howRef}>
                        <div className="how-title" style={{ height: "70px" }}>
                            How It Works
                        </div>
                        <div className={ widthTwo ? "small-card-container-phone" : "small-card-container" }>
                            <SmallCard svg={<OnlyLogo />} text={"Build Evaluation"}/>
                            <SmallCard svg={<Checkmark />} text={"Students complete assignment/assessment"}/>
                            <SmallCard svg={<Grade />} text={"Instant autograding and data export"}/>

                            {
                                widthTwo 

                                ?

                                    <div className="bar-phone"/>

                                :

                                <>
                                    <div className="bar" style={{ top: width ? "22.5%" : "32.5%" }} />
                                </>

                            }

                            <div className="how-container" style={{ flexDirection: width ? "column" : "" }}>
                                <HowWorksRow svg={<Globe />} text={"Leverage Growing Personal Computer Use"} />
                                <HowWorksRow svg={<Institution />} text={"Employ Institutionally-Provided Computers"} />
                                <HowWorksRow svg={<Hyrbid />} text={"Combine GoValuate with Paper & Pencil"} />
                            </div>
                        </div>
                    </div>  
                    <div className="how-it-works" style={{ marginTop: "300px" }} ref={props.offerRef}>
                        <div className="slant-title">
                            What We Offer
                        </div>
                        <div className="solutions-header">
                            <div className="header" style={{ width: widthThree ? "50%" : "33.3%", justifyContent: "flex-start" }}>
                                Feature
                            </div>
                            {
                                widthThree

                                ?

                                <>
                                    <div className="header" style={{ width: "50%", justifyContent: "flex-end" }}>
                                        Available & Free
                                    </div>
                                </>

                                :

                                <>
                                    <div className="header" style={{ width: "33.3%", justifyContent: "center" }}>
                                        Available & Free
                                    </div>
                                    <div className="header" style={{ width: "33.3%", justifyContent: "flex-end" }}>
                                        Coming Soon
                                    </div>
                                </>

                            }
                        </div>
                        {
                            featureSet.map((feature, index) => {
                                return <FeatureRow key={feature.feature} width={widthThree} index={index} feature={feature.feature} free={feature.free} soon={feature.soon}/>
                            })
                        }
                    </div>
                    <BottomHeader supportRef={props.supportRef}/>
                </div>
            </div>
        </>
    );
}

export default Body;