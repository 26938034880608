import React from "react";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import Support from "./support";

const BottomHeader = ({ supportRef }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="bottom-header" style={{ marginTop: "300px" }}>
                <div className="logo-container" style={{ cursor: "default", userSelect: "none" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16 16">
                        <path fill="currentColor" stroke="currentColor" strokeWidth=".3" d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707z"/>
                    </svg>
                    <div className="logo-text">
                        GoValuate
                    </div>
                </div>
                <Support supportRef={supportRef}/>
                <div className="bottom-container">
                    <div className="subtitle-bottom">
                        Owned by GoValuate LLC, Greensboro, NC.
                    </div>
                    <div className="subtitle">
                        © { moment().year() } All Rights Reserved - GoValuate LLC. <span className="bottom-priv" onClick={() => {
                            navigate("/privacy-policy");
                        }}>Privacy Policy</span> & <span className="bottom-priv" onClick={() => {
                            navigate("terms-and-conditions");
                        }}>Terms and Conditions</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BottomHeader;