import React, { useState } from "react";
import Input from "./input";
import Password from "./password";
import Enter from "./enter";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [text, setText] = useState("Log In");
    const [form, setForm] = useState({
        primaryEmail: "",
        password: ""
    });

    // have a useEffect to check whether or not user is logged in and if they are then reroute them to their role-based sub domain
    
    function updatePrimaryEmail(primaryEmail) {
        setForm(prevFrom => ({
            ...prevFrom,
            primaryEmail: primaryEmail,
        }))
        setText("Log In")
    } 
    
    function updatePassword(password) {
        setForm(prevFrom => ({
            ...prevFrom,
            password: password
        }))
        setText("Log In")
    }

    function submitForm(e) {
        e.preventDefault();

        if (form.primaryEmail.length > 255) {
            setText("Email must have 254 or fewer characters")
            return;
        }

        if (form.primaryEmail.includes(" ")) {
            setText("Remove whitespace from email");
            return;
        }
        
        // Additional email validation checks
        if (!form.primaryEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setText("Invalid email format");
            return;
        }

        if (form.password.length < 12) {
            setText("Password must be 12+ characters")
            return;
        }

        for (const value in form) {
            if (form[value] === "") {
                setText("Please enter an email address")
                return;
            }
        }

        const url = process.env.REACT_APP_USER_API_URL + "/login"

        const urlOptions = {    
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form)
        }

        makeRequest(url, urlOptions);
        
    }

    const makeRequest = async (url, urlOptions) => {
        if (requestLifeCycle) {
            return;
        }
        
        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status == 200) {
                setText("Success");
                if (data.role === "professor") {
                    window.location.href = process.env.REACT_APP_INSTRUCTOR_REDIRECT_URL;
                } else if (data.role === "student") {
                    window.location.href = process.env.REACT_APP_STUDENT_REDIRECT_URL;
                }

            } else if (res.status === 401) {
                navigate(`/confirm-email/${form.primaryEmail}/`)
            } else if (res.status === 400) {
                setText("Invalid email format")
            } else if (res.status === 409) {
                setText("Invalid credentials")
            } else if (res.status === 404) {
                setText("User not found")
            } else if (res.status === 415) {
                setText("Password contains invalid characters")
            } else {
                setText('An error occurred')
            }

        } catch (error) {
            setText("An Error Occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }

    }

    return (
        <>
            <div className="signup-container">
                <div className="add-a-course-form-container">
                    <div className="add-a-course-form-content-container">
                        <form className="form-width">
                            <div className="add-course-title">
                                GoValuate Login
                            </div>
                            <div className="account-settings-content">
                                <div className="required-container">
                                    All Fields Required
                                </div>   
                                <div className="banner top">
                                    Enter your account information
                                </div>
                                <Input top={"Email Address"} require={true} value={form.primaryEmail} updateValue={updatePrimaryEmail} placeholder={"johndoe@university.edu"} autoComplete={"email"} type={"text"}/>
                                <Password top={"Password"} required={true} password={true} value={form.password} updateValue={updatePassword} placeholder={""} autoComplete={"current-password"} type={"text"}/>
                                <div className="forgot-verify" style={{ justifyContent: "space-between" }}>
                                    <span className="actions" onClick={() => {
                                        navigate("/sign-up")
                                    }}> Sign up? </span>
                                    <span className="actions" onClick={() => {
                                        navigate("/reset-password")
                                    }}> Forgot password? </span>
                                </div>
                                <Enter requestLifeCycle={requestLifeCycle} submitForm={submitForm} text={text}/>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;