import React from "react";

const Input = (props) => {

    const handleChange = (e) => {
        props.updateValue(e.target.value)
    }
    return (
        <>
            <div className="input-field">
                <div className="top-input">
                    { props.top }
                </div>
                <input className="bottom-input" type={ props.type } value={ props.value } onChange={ handleChange } required={ props.required } autoComplete={ props.autoComplete } placeholder={ props.placeholder } />
            </div>
        </>
    );
}

export default Input;